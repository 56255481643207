<template>
  <div class="navbar navbar-expand-md bg-white border-bottom">
    <!-- Main navbar -->
    <div class="container p-0">
      <div class="navbar-brand text-left">
        <a href="/" class="d-inline-block">
          <h1 class=" font-weight-bolder logo__custom  ">
          {{site_settings.name }}
          </h1>
        </a>
      </div>

      <div class="d-md-none">
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbar-mobile"
        >
          <i class="icon-paragraph-justify3"></i>
        </button>
      </div>

      <div class="collapse navbar-collapse" id="navbar-mobile">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item">
            <!-- <router-link  class="navbar-nav-link pulse-button" :to="{ name: 'PlaceOrder' }"
              >Place Order</router-link
            > -->
          </li>

          <!-- <li class="nav-item">
            <router-link class="navbar-nav-link" :to="{ name: 'BulkOrder' }"
              >Bulk Order</router-link
            >
          </li> -->
          <li class="nav-item" v-if="isAuth">
            <router-link class="navbar-nav-link font-size-sm " :to="{ name: 'Dashboard' }"
              >Home</router-link
            >
          </li>
          <span v-if="isAuth">
            <li class="nav-item py-1">
              <button class="btn rounded-0 px-3 py-2 btn-danger" @click="doLogout">
                Logout
              </button>
            </li>
          </span>
          <li class="nav-item" v-if="!isAuth">
            <router-link class="navbar-nav-link font-size-sm" :to="{ name: 'Login' }"
              >Login</router-link
            >
          </li>

          <li class="nav-item" v-if="!isAuth">
            <router-link class="navbar-nav-link font-size-sm" :to="{ name: 'Register' }"
              >Register</router-link
            >
          </li>
        </ul>
      </div>
    </div>
    <!-- /main navbar -->
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "Nav",
  computed: {
    ...mapState("auth", ["isAuthenticated", "user"]),
    ...mapState("setups", ["site_settings"]),

    isAuth() {
      if (
        this.isAuthenticated &&
        this.user &&
        Object.keys(this.user).length > 0
      ) {
        return true;
      } else return false;
    },
  },
  methods: {
    ...mapActions("auth", ["doLogout"]),
    ...mapActions("setups", ["getSiteSettings"]),


  },

  mounted() {
    this.getSiteSettings()

  },
};
</script>

<style scoped>
.navbar--dark {
    background-color: #102341!important;
}

.pulse-button {
    color: #fff!important;
    background: #00a974;
}

.pulse-button {
    position: relative;
    display: block;
    padding: 10px 22px!important;
    font-size: 18px;
    font-weight: light;
    font-family: Trebuchet MS,sans-serif;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: -1px;
    border: none;
    border-radius: 40px!important;
    cursor: pointer;
    box-shadow: 0 0 0 0 rgb(0 169 116 / 50%);
    /* -webkit-animation: pulse 1.5s infinite; */
    /* animation: pulse 1.5s infinite; */
}

.font-size-sm{
  /* color: white !important; */
  font-size: 16px!important;
  font-weight: 600!important;
}
</style>
