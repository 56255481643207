import http from '../modules/http.index'
import Form from 'vform'
import objectToFormData from "../assets/js/objectToFormData"

const state = () => ({
  priceUrl: `${process.env.VUE_APP_API_BASE_URL}/Orders/CalculatePrice/`,
  orderUrl: `${process.env.VUE_APP_API_BASE_URL}/Orders/PlaceOrder/`,
  orderUpdateUrl: `${process.env.VUE_APP_API_BASE_URL}/Orders/update/`,
  fileUploadUrl: `${process.env.VUE_APP_API_BASE_URL}/Orders/Upload/`,
  changeStatusUrl: `${process.env.VUE_APP_API_BASE_URL}/Orders/UpdateStatus/`,
  price: {},
  order: {},
  loading: false,
  orderForm: new Form({
    order_id: "",
    academic_level: "",
    subject_area: "",
    urgency: "",
    document_type: "",
    spacing: "",
    writer_level: "",
    additional_services: [],
    pages: 0,
    charts: 0,
    slides: 0,
    files: [],
    file_type: ""
  }),

  

  deadline: '',

  statusForm: new Form({
    status: '',
    message: '',
    order_id: '',
    urgency: "",

  }),
  coupon: "",
  message: []
})

const getters = {
  getPrice: (state) => {
    return state.price
  },
  // setDeadline: (state, payload) => state.deadline = payload,
  
}

const mutations = {

  setPrice: (state, payload) => {
    state.price = payload.Data.totals
    state.message = payload.Data.message
  },
  setOrder: (state, payload) => {
    state.order = payload
    state.deadline = "Loading"
    // init order form
    state.orderForm.order_id = payload.order_id
    state.orderForm.academic_level = payload.academic_level
    state.orderForm.subject_area = payload.subject_area
    state.orderForm.urgency = payload.urgency
    state.orderForm.document_type = payload.document_type
    state.orderForm.spacing = payload.spacing
    state.orderForm.writer_level = payload.writer_level
    state.orderForm.additional_services = payload.additional_services
    // state.orderForm.pages = payload.pages
    // state.orderForm.charts = payload.charts
    // state.orderForm.slides = payload.slides

  },

  

  unsetOrder: (state) => {
    state.order = {}
    state.price = {}
    state.deadline = "Loading"
  },

  _setUploadFiles: (state, payload) => {
    state.orderForm.files = payload
  },

  updatePages(state, option) {
    if (!option) {
      if (state.orderForm.pages > 0) {
        state.orderForm.pages--
      }
    } else {
      if (state.orderForm.pages <= 1000) {
        state.orderForm.pages++
      }
    }

    // this.calculatePrice()
  },



  updateSlides(state, option) {
    if (!option) {
      if (state.orderForm.slides > 0) {
        state.orderForm.slides--
      }
    } else {
      state.orderForm.slides++
    }
    // state.calculatePrice()
  },

  updateCharts(state, option) {
    if (!option) {
      if (state.orderForm.charts > 0) {
        state.orderForm.charts--
      }
    } else {
      state.orderForm.charts++
    }
    // state.calculatePrice()
  },

  setCoupon(state, payload) { state.coupon = payload },

}


const actions = {
  async _calculatePrice(ctx, payload) {
    return new Promise((resolve, reject) => {
      console.log("calculating price: ", ctx.order);
      payload
        .post(ctx.state.priceUrl)
        .then((res) => {
          if (res.status === 200) {
            ctx.commit("setPrice", res.data);
            resolve(res);
          }
          else reject(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  async _getCoupon(ctx) {
    return new Promise((resolve, reject) => {
      console.log("calculating price: ", ctx.order);
      http
        .get('/Orders/Coupons/')
        .then((res) => {
          console.log("coupons",res);
          if (res.status === 200) {
            ctx.commit("setCoupon", res.data.Data.message);
            resolve(res);
          }
          else reject(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  async _placeOrder(ctx, payload) {
    return new Promise((resolve, reject) => {
      payload
        .post(ctx.state.orderUrl, {
          transformRequest: [function (data) {
            return objectToFormData(data)
          }]
        })
        .then((res) => {
          // if (res.status === 200) {
          resolve(res);
          // }
          // else reject(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // async _updateOrder() {
    // return new Promise((resolve, reject) => {})
  // },


  async _getOrder(ctx, id) {
    return new Promise((resolve, reject) => {
      console.log("order id", id);
      http
        .get(`/Orders/Get/${id}`)
        .then(res => {
          console.log("order res", res);
          if (res.status === 200 && res.data.Data) {
            ctx.commit("setOrder", res.data.Data);
            resolve(res);
          } else reject(res);
        })
        .catch((err) => {
          reject(err);
        })
        .finally(() => {
          ctx.commit("isLoading", false, { root: true })
        })
    })
  },


  async updateOrder(ctx, payload) {
    return new Promise((resolve, reject) => {
      ctx.state.loading = true;
      payload.order_id = ctx.state.order.id
      payload
        .post(ctx.state.orderUpdateUrl)
        .then(res => {
          if (res.status === 200) {
            ctx.dispatch("_getOrder", payload.order_id)
            payload.reset()
            resolve(res)
          } else reject(res)
        })
        .catch((err) => reject(err))
        .finally(() => ctx.state.loading = false)
    })
  },

  async _uploadFiles(ctx, payload) {
    return new Promise((resolve, reject) => {
      ctx.state.loading = true;
      payload
        .post(ctx.state.fileUploadUrl + ctx.state.order.id, {
          transformRequest: [function (data) {
            return objectToFormData(data)
          }]
        })
        .then(res => {
          if (res.status === 200) {
            resolve(res)
            ctx.dispatch("_getOrder", ctx.state.order.id)
            ctx.commit("_setUploadFiles", [])
          } else reject(res)
        })
        .catch((err) => reject(err))
        .finally(() => ctx.state.loading = false)
    })
  },

  async _downloadFile(ctx, payload) {
    return new Promise((resolve, reject) => {
      ctx.state.loading = true
      http.get(`/Orders/Download/${payload}`, { responseType: 'blob', })
        .then(res => {
          if (res.status === 200) {
            resolve(res)
            ctx.dispatch("_getOrder", payload.order_id)
          } else reject(res)
        })
        .catch((err) => reject(err))
        .finally(() => ctx.state.loading = false)
    })
  },

  async _deleteFile(ctx, payload) {
    return new Promise((resolve, reject) => {
      ctx.state.loading = true
      console.log("payload", payload);
      http.delete(`/Files/File/${payload.file_id}`)
        .then((res) => {
            ctx.dispatch("_getOrder", payload.order_id)
            resolve(res)
        })
        .catch((err) => reject(err))
        .finally(() => ctx.state.loading = false)
    })
  },

  async _updateProgress(ctx, payload) {
    return new Promise((resolve, reject) => {
      ctx.state.loading = true
      console.log("payload", payload);
      http.post(`/orders/UpdatePercentage/${payload.order_id}/${payload.percentage_value}`)
        .then((res) => {
            ctx.dispatch("_getOrder", payload.order_id)
            resolve(res)
        })
        .catch((err) => reject(err))
        .finally(() => ctx.state.loading = false)
    })
  },

  // async _walletTopup() {
  //   return new Promise((resolve, reject) => { })
  // },
  // async _pay() { 
  //   return new Promise((resolve, reject) => { })
  // },
  // async _walletTopup() {
  //   return new Promise((resolve, reject) => { })
  // },

  // async _tipWriter() {
  //   return new Promise((resolve, reject) => { })
  // },

  async _changeStatus(ctx, payload) {
    return new Promise((resolve, reject) => {
      ctx.state.loading = true;
      payload
        .post(ctx.state.changeStatusUrl)
        .then((res) => {
          if (res.status === 200) {
            resolve(res)
            ctx.dispatch("_getOrder", payload.order_id)
          } else reject(res)
        })
        .catch(err => reject(err))
        .finally(() => ctx.state.loading = false)
    })
  },

  async deleteExtras(ctx, payload) {
    return new Promise((resolve, reject) => {
      http.post('/Orders/RemoveExtra/', {
        id: payload
      })
        .then(res => {
          if (res.status === 200) {
            ctx.dispatch("_getOrder", ctx.state.order.id)
            resolve(res)
          } else reject(res)
        })
        .catch(err => reject(err))
    })
  }





}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}