<template>
  <!-- Main navbar -->
  <div class="navbar py-sm-3 py-2 navbar-expand-md navbar-light">
    <div class="navbar-brand d-sm-none d-flex mr-0">
      <h1 class="font-weight-bolder text-dark">
        {{ site_settings.name }}
      </h1>
    </div>

    <div class="d-md-none">
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbar-mobile"
      >
        <i class="icon-tree5"></i>
      </button>
      <button
        @click="_toggleSidebarMobile"
        class="navbar-toggler sidebar-mobile-main-toggle"
        type="button"
      >
        <i class="is-icon-toggle" :class="'icon-paragraph-justify3'"></i>
      </button>
    </div>

    <div class="collapse navbar-collapse" id="navbar-mobile">
      <ul class="navbar-nav is-search">
        <form
          v-if="userType == userTypes.admin || userType == userTypes.editor || userType == userTypes.client"
          @submit.prevent="doSearch"
        >
          <v-text-field
            placeholder="Search.."
            class="border-success"
            v-model="search"
            hide-details
            outlined
            dense
          >
            <v-icon @click="doSearch" slot="append" color="green"
              >mdi-magnify</v-icon
            >
          </v-text-field>
        </form>
      </ul>

      <ul class="navbar-nav ml-auto">
        <li class="nav-item dropdown dropdown-users">
          <a href="#" class="navbar-nav-link" data-toggle="dropdown">
            <i class="icon-users"></i>
            <span class="d-md-none ml-2">Online Users</span>
            <span class="badge badge-mark border-white ml-auto ml-md-0"></span>
          </a>
          <div
            class="dropdown-menu dropdown-menu-right"
            style="max-height: 400px;overflow: auto;"
          >
            <span v-if="online_users && online_users.length > 0">
              <a
                v-for="(user, index) in online_users"
                :key="index"
                
                class="dropdown-item"
              >
              <!-- :href="`/home/profile/${user.id}`" -->
                <!-- <i class="icon-bell2 text-success"></i> -->
                {{ user.names }}
                <span class="nav-link">{{ user.timediff }}</span>
              </a>
            </span>
          </div>
        </li>

        <li class="nav-item dropdown dropdown-notifications">
          <a href="#" class="navbar-nav-link" data-toggle="dropdown">
            <i class="icon-bell2"></i>
            <span class="d-md-none ml-2">Notifications</span>
            <!-- <span class="badge badge-mark bg-danger border-white ml-auto ml-md-0" >1</span> -->
            <span
              v-if="notifications && notifications.length > 0"
              class="badge bg-danger border-white ml-auto ml-md-0"
            >
              {{ notifications.length }}
            </span>
          </a>

          <div style="max-height: 400px;overflow: auto;" class="dropdown-menu notifications_section dropdown-menu-right ">
            
            <span v-if="notifications && notifications.length > 0">
              <span class="dropdown-item"
                >
                You have {{ notifications && notifications.length || 0 }} unread messages
              </span>

              <a v-for="(notification, index) in notifications" :key="index" :href="`/home/order/${notification.order_id}`"  class="dropdown-item"
                >
                <i v-if="notification.order_id != 0" class="icon-comment-discussion text-danger "></i>
                <div v-if="notification.order_id != 0"  class="d-flex items-center justify-between notifications_section_inner w-100 p-1">
                  <span>Order Number: {{notification.order_id}}</span>
                  <span class="no_unread_notifications text-danger font-extrabold ml-1" >{{ notification.no }} unread </span>

                </div>
              </a>
            </span>
             <span v-else >
              <span class="dropdown-item"
                >
                No Unread Notifications
              </span>
            </span>


          </div>
        </li>

        <li class="nav-item dropdown dropdown-user">
          <a
            href="#"
            class="navbar-nav-link d-flex align-items-center dropdown-toggle"
            data-toggle="dropdown"
          >
            <img
              src="../assets/global_assets/images/image.png"
              class="rounded-circle mr-2"
              height="32"
              alt=""
            />
            <span>
              {{ user.first_name || user.last_name }}
            </span>
          </a>

          <div class="dropdown-menu dropdown-menu-right">
            <router-link :to="{ name: 'Profile' }" class="dropdown-item"
              ><i class="icon-user-plus"></i> My profile</router-link
            >
            <router-link :to="{ name: 'Balance' }" class="dropdown-item"
              ><i class="icon-coins"></i> My balance</router-link
            >
            <router-link :to="{ name: 'Payments' }" class="dropdown-item"
              ><i class="icon-comment-discussion"></i> Payments
              <!-- <span class="badge badge-pill bg-blue ml-auto">58</span> -->
            </router-link>
            <div class="dropdown-divider"></div>

            <a @click="doLogout" class="dropdown-item"
              ><i class="icon-switch2"></i> Logout</a
            >
          </div>
        </li>
      </ul>
    </div>
  </div>
  <!-- /main navbar -->
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
import Alerts from "../../mixins/alerts";
export default {
  name: "Navbar",
  computed: {
    ...mapState("auth", ["user"]),
    ...mapState("sidebar", ["showSidebarMobile"]),
    ...mapState("setups", ["site_settings"]),
    ...mapGetters("auth", ["userType", "userTypes"]),
  },
  data() {
    return {
      search: "",
      notifications: [],
      online_users: [],
    };
  },
  methods: {
    ...mapActions("auth", ["doLogout"]),
    ...mapActions("setups", ["getSiteSettings"]),

    ...mapMutations("sidebar", ["_toggleSidebar", "_toggleSidebarMobile"]),
    doSearch() {
      document.location.href = `/home/search?search=${this.search}`;
    },

    getNotifications() {
      this.$req
        .apiGet("/Notifications/notifications")
        .then(({ data }) => {
          this.notifications = data.Data;
        })
        .catch((err) => console.log("noti error =====>", err));
    },
    getOnlineUsers() {
      this.$req
        .apiGet("/system/onlineusers")
        .then(({ data }) => {
          this.online_users = data.Data;
        })
        .catch((err) => console.log("noti error =====>", err));
    },
  },
  mounted() {
    console.log("mounted");
    this.search = this.$route.query.search;
    this.getOnlineUsers();
    this.getNotifications();
    this.getSiteSettings();
  },
  mixins: [Alerts],
};
</script>

<style lang="scss" scoped>
.is-icon-toggle {
  transition: 0.3s all ease-in-out;
}
.is-search {
  width: 100%;
  form {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .is-search {
    width: 100% !important;
    margin-bottom: 20px;
    margin-top: 20px;
    form {
      width: 100% !important;
    }
  }
}

@media screen and (max-width: 480px) {
  .is-search {
    width: 100% !important;
    margin-bottom: 20px;
    margin-top: 20px;
    form {
      width: 100% !important;
      // margin-top: 12px;
    }
  }
}
</style>
